<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="clientDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline">Add Client</span>
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: column;"
        >
          <editable-avatar :onImageSelected="onImageSelected" />
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="client.first_name"
                  label="First Name"
                  type="name"
                  @onChange="onChange"
                  required
                  dense
                  @blur="onCheckDuplicatedNames"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.last_name"
                  label="Last Name"
                  type="name"
                  @onChange="onChange"
                  required
                  dense
                  @blur="onCheckDuplicatedNames"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="sameClients.length">
                <div v-for="item in sameClients" :key="item._id" class="mb-3">
                  <div class="d-flex">
                    <editable-avatar
                      :editable="false"
                      :image="item.client ? item.client.image : ''"
                      :size="40"
                    />
                    <div class="d-flex flex-column ml-3">
                      <div>
                        <span
                          style="font-weight: bold; color: #848299; margin-right: 12px;"
                          >{{ item.client.client_id }}</span
                        >
                        <span
                          style="color: #1c1c1a; font-family: 'Poppins-Medium'"
                          v-html="
                            getFiltered(
                              item.client.first_name +
                                `${
                                  item.client.last_name
                                    ? ' ' + item.client.last_name
                                    : ''
                                }`
                            )
                          "
                        />
                      </div>
                      <div style="font-family: 'Poppins-Medium'">
                        {{ formatPhoneNumber(item.client.phone) }}
                      </div>
                      <div style="font-family: 'Poppins-Medium'">
                        {{ item.client.email }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="family in item.family"
                    :key="family.index"
                    class="ml-15"
                  >
                    <span style="font-weight: bold; margin-right: 5px;"
                      >{{ family.index }}.</span
                    >
                    <span v-html="getFiltered(family.value)" />
                  </div>
                  <v-divider class="mt-3" />
                </div>
                <div v-if="totalClients > sameClients.length">
                  And more {{ totalClients - sameClients.length }} families
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.email"
                  label="Email*"
                  type="email"
                  @onChange="onChange"
                  :rules="[rules.required, rules.email]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.phone"
                  label="Phone*"
                  type="phone"
                  @onChange="onChange"
                  :rules="[rules.required, rules.phone]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="client.password"
                  label="Password*"
                  type="password"
                  @onChange="onChange"
                  :rules="[rules.required, rules.min]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <vuetify-google-autocomplete
                  id="map"
                  placeholder="Address"
                  v-model="client.address"
                  v-on:placechanged="getAddressData"
                  ref="address"
                  dense
                >
                </vuetify-google-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="client.address2"
                  label="Address 2"
                  type="address"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="client.city"
                  label="City"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="client.state"
                  label="State"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="client.zip"
                  label="Zip"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text class="pt-0 pl-9">
          <small>*indicates required field</small>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import { mapActions } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: {
    VuetifyGoogleAutocomplete,
    EditableAvatar,
  },
  props: {
    clientDialog: {
      type: Boolean,
      default: false,
    },
    onCloseClientDialog: {
      type: Function,
    },
    onAddedClient: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      client: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        password: "",
        image: null,
      },
      error: null,
      loading: false,
      sameClients: [],
      totalClients: 0,
    };
  },
  methods: {
    ...mapActions({
      addClient: "client/addClient",
      nameSeaches: "intake/nameSearches",
    }),
    onCheckDuplicatedNames() {
      this.nameSeaches({
        name: this.client.first_name + " " + this.client.last_name,
      })
        .then((res) => {
          this.sameClients = res.data;
          this.totalClients = res.count;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(
        words,
        `${this.client.first_name + " " + this.client.last_name}`.trim()
      );
      return filterd;
    },
    onSave() {
      this.loading = true;
      var formData = new FormData();
      Object.keys(this.client).map((key) => {
        if (this.client[key]) formData.append(key, this.client[key]);
      });

      this.addClient(formData)
        .then((res) => {
          this.loading = false;
          this.error = null;
          this.onAddedClient(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = error;
        });
    },
    onImageSelected(image) {
      this.client.image = image;
    },
    getAddressData(addressData) {
      this.client.address = addressData.name;
      this.client.state = addressData.administrative_area_level_1;
      this.client.city = addressData.locality;
      this.client.zip = addressData.postal_code;
      this.client.location = addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.client.address);
    },
    onClose() {
      this.client = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        password: "",
        image: null,
      };
      this.loading = false;
      this.error = null;
      this.onCloseClientDialog();
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    onChange() {
      this.error = null;
    },
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
