<template>
  <v-container
    fluid
    style="display:flex; flex-direction: column; height: 100%; padding: 0;"
    v-if="profileType == 'Partner'"
  >
    <Menu
      style="position:fixed; height: 50px; z-index: 1; left: 0; right: 0; background: #DBC7FF"
      :settings="false"
      :title="'Back'"
    />
    <div
      style="display:flex; flex-direction: row; margin-top: 50px; overflow-x: hidden; overflow-y:auto; background: #F4F9FF; height: 100%"
    >
      <v-navigation-drawer
        permanent
        :width="$vuetify.breakpoint.smAndDown ? 65 : 500"
        color="#F4F9FF"
      >
        <template v-slot:prepend>
          <v-list-item two-line class="hidden-sm-and-down">
            <v-list-item-content>
              <v-list-item-title class="setting-title"
                >Settings</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list dense>
          <v-list-item-group v-model="selected" active-class="selected-menu">
            <v-list-item v-for="item in items" :key="item.title" link>
              <v-list-item-icon class="pl-md-10">
                <v-icon color="#6e3dc6">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="name-title hidden-sm-and-down">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <div style="background-color: lightgrey; width: 1px" />
      <router-view
        width="100%"
        style="padding-right: 30px; background: #F4F9FF"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "../../components/core/Menu.vue";

export default {
  components: { Menu },
  data() {
    return {
      items: [
        {
          title: "Account",
          icon: "mdi-account",
          route: "partner-account-settings",
        },
        {
          title: "Password",
          icon: "mdi-account-key",
          route: "partner-password-settings",
        },
        {
          title: "Variables",
          icon: "mdi-variable",
          route: "partner-variable-settings",
        },
      ],
      selected: [],
    };
  },
  watch: {
    selected(newValue) {
      if (newValue != undefined) {
        var route = this.items[newValue].route;
        if (route) {
          if (this.$route.name != route) {
            this.$router.push({
              name: route,
            });
          }
          return;
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", {
      profileType: "getType",
    }),
  },
  mounted() {
    var routeName = this.$route.name;
    if (routeName == "account-settings") {
      this.selected = 0;
    }
  },
};
</script>
<style scope>
.setting-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.selected-menu {
  color: white !important;
  caret-color: white !important;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
