<template>
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :filename="`Client ${data.client_id} Summary`"
      :pdf-quality="2"
      pdf-format="a4"
      :paginate-elements-by-height="1400"
      :manual-pagination="false"
      @beforeDownload="loading = true"
      @hasDownloaded="onDone"
    >
      <section slot="pdf-content">
        <div
          ref="pdf_content"
          style="margin-top: 20px; margin-left: 30px;margin-right: 30px; width: 450px;background: white;"
        >
          <div
            style="width: 100%; justify-content: center; display: flex; font-size: 24px; font-weight: bold;border-collapse: collapse;"
          >
            Item Info
          </div>
          <div
            style="width: 100%; justify-content: center; display: flex; margin-top: 15px; font-weight: bold;"
          >
            Food Calculation for 7 days
          </div>
          <div
            style="width: 100%; justify-content: center; display: flex; margin-top: 10px;"
          >
            Order Week:
            <span style="font-weight: bold; margin-left: 10px;">
              {{ startDate }} - {{ endDate }}</span
            >
          </div>
          <div style="margin-top: 10px;">
            Client ID:
            <span style="font-weight: bold;">{{ data.client_id }}</span>
          </div>
          <table class="data-table">
            <tr>
              <th
                colspan="5"
                style="padding-top: 5px; padding-bottom: 5px; border-bottom: 0.5px solid gray;"
              >
                Order Item Details ( total: {{ data.meals }} meals )
              </th>
            </tr>
            <tr>
              <th
                style="border-right: 0.5px solid gray; width: 100px; font-size: 12px;"
              >
                Item Name
              </th>
              <th
                style="border-right: 0.5px solid gray; width: 150px;font-size: 12px;"
              >
                Tag Name
              </th>
              <th
                style="border-right: 0.5px solid gray; padding-top: 3px; padding-bottom: 3px; width: 100px;font-size: 12px;"
              >
                Number of<br />
                Serving
              </th>
              <th
                style="border-right: 0.5px solid gray; width: 100px;font-size: 12px;"
              >
                Serving size
              </th>
              <th style="font-size: 12px;">Notes</th>
            </tr>
            <tr v-if="data.fruits">
              <th
                colspan="5"
                style="color: #6e3dc6;padding-left: 10px; padding-top: 5px; padding-bottom: 5px; text-align: left; border-top: 0.5px solid gray;"
              >
                Fruits ( {{ data.fruits.amount }} servings )
              </th>
            </tr>
            <tr
              v-for="item in data.fruits && data.fruits.items
                ? data.fruits.items
                : []"
              :key="item.food._id"
              class="top-border"
            >
              <td class="right-border" style="width: 100px; font-size: 13px;">
                {{ item.food.name }}
              </td>
              <td class="right-border" style=" width: 150px;font-size: 13px;">
                <div v-for="tag in item.food.tags" :key="tag">
                  {{ tag }}
                </div>
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.meals }}
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.serving_size }} {{ item.food.unit }}
              </td>
              <td style="font-size: 13px;">{{ item.food.description }}</td>
            </tr>
            <tr v-if="data.vegetables">
              <th
                colspan="5"
                style="color: #6e3dc6;padding-left: 10px; padding-top: 5px; padding-bottom: 5px; text-align: left; border-top: 0.5px solid gray;"
              >
                Vegetables ( {{ data.vegetables.amount }} servings )
              </th>
            </tr>
            <tr
              v-for="item in data.vegetables && data.vegetables.items
                ? data.vegetables.items
                : []"
              :key="item.food._id"
              class="top-border"
            >
              <td class="right-border" style="width: 100px;  font-size: 13px;">
                {{ item.food.name }}
              </td>
              <td class="right-border" style=" width: 150px; font-size: 13px;">
                <div v-for="tag in item.food.tags" :key="tag">
                  {{ tag }}
                </div>
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.meals }}
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.serving_size }} {{ item.food.unit }}
              </td>
              <td style="font-size: 13px;">{{ item.food.description }}</td>
            </tr>

            <tr v-if="data.grains">
              <th
                colspan="5"
                style="color: #6e3dc6;padding-left: 10px; padding-top: 5px; padding-bottom: 5px; text-align: left; border-top: 0.5px solid gray;"
              >
                Grains ( {{ data.grains.amount }} servings )
              </th>
            </tr>
            <tr
              v-for="item in data.grains && data.grains.items
                ? data.grains.items
                : []"
              :key="item.food._id"
              class="top-border"
            >
              <td class="right-border" style="width: 100px;  font-size: 13px;">
                {{ item.food.name }}
              </td>
              <td class="right-border" style=" width: 150px;font-size: 13px;">
                <div v-for="tag in item.food.tags" :key="tag">
                  {{ tag }}
                </div>
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.meals }}
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.serving_size }} {{ item.food.unit }}
              </td>
              <td style="font-size: 13px;">{{ item.food.description }}</td>
            </tr>
            <tr v-if="data.protein">
              <th
                colspan="5"
                style="color: #6e3dc6;padding-left: 10px; padding-top: 5px; padding-bottom: 5px; text-align: left; border-top: 0.5px solid gray;"
              >
                Protein ( {{ data.protein.amount }} servings )
              </th>
            </tr>
            <tr
              v-for="item in data.protein && data.protein.items
                ? data.protein.items
                : []"
              :key="item.food._id"
              class="top-border"
            >
              <td class="right-border" style="width: 100px; font-size: 13px; ">
                {{ item.food.name }}
              </td>
              <td class="right-border" style=" width: 150px;font-size: 13px;">
                <div v-for="tag in item.food.tags" :key="tag">
                  {{ tag }}
                </div>
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.meals }}
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.serving_size }} {{ item.food.unit }}
              </td>
              <td style="font-size: 13px;">{{ item.food.description }}</td>
            </tr>
            <tr v-if="data.dairy">
              <th
                colspan="5"
                style="color: #6e3dc6;padding-left: 10px; padding-top: 5px; padding-bottom: 5px; text-align: left; border-top: 0.5px solid gray;"
              >
                Dairy ( {{ data.dairy.amount }} servings )
              </th>
            </tr>
            <tr
              v-for="item in data.dairy && data.dairy.items
                ? data.dairy.items
                : []"
              :key="item.food._id"
              class="top-border"
            >
              <td class="right-border" style="width: 100px;  font-size: 13px;">
                {{ item.food.name }}
              </td>
              <td class="right-border" style="width: 150px; font-size: 13px;">
                <div v-for="tag in item.food.tags" :key="tag">
                  {{ tag }}
                </div>
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.meals }}
              </td>
              <td class="right-border" style=" width: 100px; font-size: 13px;">
                {{ item.food.serving_size }} {{ item.food.unit }}
              </td>
              <td style="font-size: 13px;">{{ item.food.description }}</td>
            </tr>
          </table>

          <!-- {{ data }} -->
        </div>
      </section>
    </vue-html2pdf>
    <v-snackbar v-model="snackbar">
      {{ error }}
    </v-snackbar>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapActions } from "vuex";
import dateFormat from "dateformat";
import jsPDF from "jspdf";

export default {
  props: {
    client_id: String,
  },
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      loading: false,
      data: [],
      startDate: null,
      endDate: null,
      error: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions({
      getSummary: "distribution/clientSummary",
    }),
    generateReport() {
      var date = new Date();
      this.startDate = dateFormat(date, "mmmm dd yyyy");
      date.setDate(date.getDate() + 7);
      this.endDate = dateFormat(date, "mmmm dd yyyy");
      this.getSummary(this.client_id)
        .then((res) => {
          this.data = res;
          //   if (this.$refs.html2Pdf) {
          //     this.$refs.html2Pdf.generatePdf();
          //   } else {
          //     console.log("not ready");
          //   }
          var doc = new jsPDF("p", "pt", "a4");
          doc.html(this.$refs.pdf_content, {
            x: 10,
            y: 10,
            margin: [50, 40, 50, 40],
            callback: function(doc) {
              const pageCount = doc.internal.getNumberOfPages();

              doc.setFont("times", "bold");

              for (var i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.setFontSize(20);
                doc.text(
                  "Daniel's Table",
                  doc.internal.pageSize.width - 150,
                  30
                );
                doc.setLineWidth(0, 5);
                doc.line(20, 40, doc.internal.pageSize.width - 20, 40);
                doc.setFontSize(12);
                doc.text(
                  "Page " + String(i) + " of " + String(pageCount),
                  doc.internal.pageSize.width / 2,
                  doc.internal.pageSize.height - 10,
                  {
                    align: "center",
                  }
                );
              }
              doc.save(res.client_id + ".pdf");
            },
          });
          doc.addPage();
          //   doc.autoPrint();
          //   doc.save(res.client_id + ".pdf");
        })
        .catch((error) => {
          if (error.response) {
            this.snackbar = true;
            this.error = error.response.data.message;
          } else {
            this.snackbar = true;
            this.error = error;
          }
        });
    },
    onDone() {
      this.loading = false;
    },
  },
  mounted() {
    this.generateReport();
  },
};
</script>
<style>
.data-table {
  border: 0.5px solid gray;
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  table-layout: fixed;
  word-wrap: break-word;
}
td {
  text-align: center;
  padding: 10px;
}
.right-border {
  border-right: 0.5px solid gray;
}
.bottom-border {
  border-bottom: 0.5px solid gray;
}
.top-border {
  border-top: 0.5px solid gray;
}
</style>
