<template>
  <div style="width: 100%; background:'#dbc7ff' ">
    <v-tabs
      height="50"
      style="max-height: 50; width: 100%; padding-right:20px; background: #dbc7ff"
      v-model="selected"
      right
      background-color="#dbc7ff"
    >
      <v-tab v-if="title" @click="onBack()">
        <v-icon class="mr-5" v-text="'mdi-chevron-left'" />{{ title }}
      </v-tab>
      <v-spacer />
      <v-tab
        v-for="(menu, index) in menus"
        v-bind:key="index"
        @click="onSelected(index, $event)"
      >
        <v-icon color="#482684" v-text="menu.icon" />
        <div class="ml-2" style="color:#482684" v-text="menu.title" />
      </v-tab>
    </v-tabs>
    <client-dialog
      :clientDialog="clientDialog"
      :onCloseClientDialog="onCloseClientDialog"
      :onAddedClient="onAddedClient"
      v-if="clientDialog"
    />
    <nutritional-info-dialog :dialog="nutriDialog" :onClose="onCloseDialog" />
    <add-organization-dialog
      :dialog="organizationDialog"
      :onCloseDialog="onCloseDialog"
      v-if="organizationDialog"
    />
    <employee-dialog
      :dialog="employeeDialog"
      :onCloseDialog="onCloseDialog"
      :item="{ organization: profile.organization._id }"
      v-if="
        employeeDialog && profileType == 'Partner' && profile.role == 'Manager'
      "
    />
    <ClientPDFSummary
      v-if="summaryDialog"
      :client_id="this.$route.params.id"
      ref="summary"
    />
    <FoodPDFSummary v-if="barcodeSummaryDialog" ref="barcode" />
    <v-menu
      v-model="intakeDateMenu"
      absolute
      offset-y
      :position-x="dateX"
      :position-y="dateY"
      :close-on-content-click="false"
      :return-value.sync="intakeDate"
      style="z-index: 11"
    >
      <v-date-picker v-model="intakeDate" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="intakeDateMenu = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="onUpdateIntakeDate">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FoodPDFSummary from "../admin/FoodPDFSummary.vue";
import ClientDialog from "../client/ClientDialog.vue";
import ClientPDFSummary from "../client/ClientPDFSummary.vue";
import NutritionalInfoDialog from "../NutritionalInfoDialog.vue";
import AddOrganizationDialog from "../partner/AddOrganizationDialog.vue";
import EmployeeDialog from "../partner/EmployeeDialog.vue";
import dateFormat from "dateformat";
export default {
  props: {
    question: {
      type: Boolean,
      default: false,
    },
    client: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Boolean,
      default: false,
    },
    freezer: {
      type: Boolean,
      default: false,
    },
    food: {
      type: Boolean,
      default: false,
    },
    referral: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Boolean,
      default: true,
    },
    nutri_info: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    summary: {
      type: Boolean,
      default: false,
    },
    barcode: {
      type: Boolean,
      default: false,
    },
    employee: {
      type: Boolean,
      default: false,
    },
    intake_date: {
      type: Boolean,
      default: false,
    },
    handleBack: Function,
  },
  components: {
    ClientDialog,
    NutritionalInfoDialog,
    AddOrganizationDialog,
    ClientPDFSummary,
    EmployeeDialog,
    FoodPDFSummary,
  },
  data() {
    return {
      menus: [
        {
          title: "Home",
          icon: "mdi-view-dashboard-variant-outline",
          router: "/",
        },
        {
          title: "Questions",
          icon: "mdi-help-circle-outline",
          router: "questions",
        },
        {
          title: "Add new client",
          icon: "mdi-account-plus-outline",
          router: "add-client",
        },
        {
          title: "Settings",
          icon: "mdi-cog-outline",
          router: "/settings/account",
        },
        { title: "Logout", icon: "mdi-logout", router: "/logout" },
      ],
      selected: 0,
      clientDialog: false,
      nutriDialog: false,
      organizationDialog: false,
      freezerDialog: false,
      summaryDialog: false,
      barcodeSummaryDialog: false,
      employeeDialog: false,
      intakeDateMenu: false,
      intakeDate: null,
      dateX: 365,
      dateY: 120,
    };
  },
  methods: {
    ...mapActions("auth", {
      onLogout: "onLogout",
    }),
    onSelected(index, event) {
      if (this.menus[index].router === "/") {
        this.$router.push({ name: "home" });
      } else if (this.menus[index].router === "add-client") {
        this.clientDialog = true;
      } else if (this.menus[index].router === "add-partner") {
        this.organizationDialog = true;
      } else if (this.menus[index].router === "add-employee") {
        this.employeeDialog = true;
      } else if (this.menus[index].router === "add-freezer") {
        this.$router.push({
          name: "freezer-details",
          params: { id: "add" },
        });
      } else if (
        this.menus[index].router === "summary" &&
        this.profileType == "Admin"
      ) {
        if (this.summaryDialog) {
          this.$refs.summary.generateReport();
        }
        this.summaryDialog = true;
      } else if (this.menus[index].router === "barcode-summary") {
        if (this.barcodeSummaryDialog) {
          this.$refs.barcode.generateReport();
        }
        this.barcodeSummaryDialog = true;
      } else if (this.menus[index].router === "nutri_info") {
        this.nutriDialog = true;
      } else if (this.menus[index].router === "/logout") {
        this.onLogout().then(() => {
          this.$router.push({ name: "login" });
        });
      } else if (this.menus[index].router == "intake_date") {
        this.intakeDateMenu = true;
        this.dateX = event.pageX;
        this.dateY = event.pageY;
      } else {
        this.$router.push({ name: this.menus[index].router });
      }
    },
    onCloseClientDialog() {
      this.clientDialog = false;
    },
    onAddedClient(client) {
      this.clientDialog = false;
      this.$router.push({
        name: "intake",
        params: { id: client._id },
        query: { new: true },
      });
    },
    onCloseDialog() {
      this.nutriDialog = false;
      this.organizationDialog = false;
      this.employeeDialog = false;
    },
    onBack() {
      if (window.history.length > 2) {
        this.$router.back();
      } else if (this.handleBack) {
        this.handleBack();
      }
    },
    refreshMenu() {
      this.menus = [
        {
          title: "Home",
          icon: "mdi-view-dashboard-variant-outline",
          router: "/",
        },
      ];
      if (this.summary && this.profileType == "Admin") {
        this.menus.push({
          title: "Summary",
          icon: "mdi-text-box-check-outline",
          router: "summary",
        });
      }
      if (this.intake_date && this.profileType == "Admin") {
        this.menus.push({
          title: this.intakeDateStr,
          icon: "mdi-calendar-outline",
          router: "intake_date",
        });
      }
      if (this.barcode) {
        this.menus.push({
          title: "Food Summary",
          icon: "mdi-text-box-check-outline",
          router: "barcode-summary",
        });
      }
      if (this.question) {
        this.menus.push({
          title: "Questions",
          icon: "mdi-help-circle-outline",
          router: "questions",
        });
      }
      if (this.client && this.profileType == "Admin") {
        this.menus.push({
          title: "Add new client",
          icon: "mdi-account-plus-outline",
          router: "add-client",
        });
      }
      if (this.partner) {
        this.menus.push({
          title: "Add new organization",
          icon: "mdi-account-multiple-plus-outline",
          router: "add-partner",
        });
      }
      if (
        this.employee &&
        this.profileType == "Partner" &&
        this.profile.role == "Manager"
      ) {
        this.menus.push({
          title: "Add new employee",
          icon: "mdi-account-plus-outline",
          router: "add-employee",
        });
      }
      if (this.freezer) {
        this.menus.push({
          title: "Add new freezer",
          icon: "mdi-account-plus-outline",
          router: "add-freezer",
        });
      }
      if (this.food) {
        this.menus.push({
          title: "Food Management",
          icon: "mdi-food",
          router: "foods",
        });
      }
      if (this.referral) {
        this.menus.push({
          title: "Add new referral",
          icon: "mdi-table-plus",
          router: "add-referral",
        });
      }
      if (this.nutri_info) {
        this.menus.push({
          title: "Nutritional Information",
          icon: "mdi-nutrition",
          router: "nutri_info",
        });
      }
      if (this.settings && this.profileType == "Admin") {
        this.menus.push({
          title: "Settings",
          icon: "mdi-cog-outline",
          router: "account-settings",
        });
      }
      this.menus.push({
        title: "Logout",
        icon: "mdi-logout",
        router: "/logout",
      });
    },
    onUpdateIntakeDate() {
      this.intakeDateStr = this.changeDateFormat(this.intakeDate);
      this.intakeDateMenu = false;
      this.refreshMenu();
      if (this.$route.query.date != this.intakeDateStr) {
        const today = dateFormat(new Date(), "mm/dd/yyyy");
        if (today == this.intakeDateStr) {
          if (this.$route.query.date) {
            let params = { ...this.$route.query, date: null };
            Object.keys(params).map((k) =>
              params[k] == undefined ? delete params[k] : params[k]
            );
            this.$router.replace({
              query: params,
            });
          }
        } else {
          this.$router.replace({
            query: {
              ...this.$route.query,
              date: this.intakeDateStr,
            },
          });
        }
      }
    },
    changeDateFormat(dateStr) {
      return `${dateStr.substring(5, 7)}/${dateStr.substring(
        8,
        10
      )}/${dateStr.substring(0, 4)}`;
    },
  },
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
      profileType: "getType",
    }),
  },
  watch: {
    profile(newValue) {
      if (newValue) {
        this.refreshMenu();
      }
    },
    "$route.query.date": function(newValue) {
      if (newValue != this.intakeDateStr) {
        if (newValue) this.intakeDateStr = newValue;
        else {
          this.intakeDateStr = dateFormat(new Date(), "mm/dd/yyyy");
        }
        this.refreshMenu();
      }
    },
  },
  mounted() {
    if (this.$route.query.date) {
      this.intakeDateStr = this.$route.query.date;
    } else {
      this.intakeDateStr = dateFormat(new Date(), "mm/dd/yyyy");
    }
    this.refreshMenu();
  },
};
</script>
<style scoped>
div >>> .v-tabs {
  max-height: 50px;
}
div >>> .v-tabs-slider {
  display: none;
}
div >>> .v-date-picker-table {
  height: auto;
}
div >>> .v-date-picker-table > table > tbody > tr > td {
  padding: 0 !important;
}
</style>
