<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title
        class="pb-0 pt-8"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <div style="font-size: 20px">
          Nutritional Information
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          class="elevation-0"
          hide-default-footer
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="blue white" @click="onClose" class="white--text">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          name: "Fruits",
          "2-M": 1,
          "2-F": 1,
          "3-5 M": 1.5,
          "3-5 F": 1.5,
          "6-8 M": 1.5,
          "6-8 F": 1.5,
          "9-11 M": 1.5,
          "9-11 F": 1.5,
          "12-14 M": 2,
          "12-14 F": 2,
          "15-25 M": 2.5,
          "15-25 F": 2,
          "26-65 M": 2,
          "26-65 F": 2,
          ">65 M": 2,
          ">65 F": 1.5,
        },
        {
          id: 2,
          name: "Vegetables",
          "2-M": 1,
          "2-F": 1,
          "3-5 M": 1.5,
          "3-5 F": 1.5,
          "6-8 M": 2,
          "6-8 F": 2,
          "9-11 M": 2.5,
          "9-11 F": 2.5,
          "12-14 M": 3,
          "12-14 F": 2.5,
          "15-25 M": 3.5,
          "15-25 F": 3,
          "26-65 M": 3.5,
          "26-65 F": 2.5,
          ">65 M": 3,
          ">65 F": 2.5,
        },
        {
          id: 3,
          name: "Grains",
          "2-M": 3,
          "2-F": 3,
          "3-5 M": 5,
          "3-5 F": 5,
          "6-8 M": 5,
          "6-8 F": 5,
          "9-11 M": 6,
          "9-11 F": 6,
          "12-14 M": 7,
          "12-14 F": 6,
          "15-25 M": 10,
          "15-25 F": 7,
          "26-65 M": 9,
          "26-65 F": 6,
          ">65 M": 7,
          ">65 F": 6,
        },
        {
          id: 4,
          name: "Protein",
          "2-M": 0.5,
          "2-F": 0.5,
          "3-5 M": 1.25,
          "3-5 F": 1.25,
          "6-8 M": 1.25,
          "6-8 F": 1.25,
          "9-11 M": 1.25,
          "9-11 F": 1.25,
          "12-14 M": 1.5,
          "12-14 F": 1.4,
          "15-25 M": 1.75,
          "15-25 F": 1.5,
          "26-65 M": 1.6,
          "26-65 F": 1.4,
          ">65 M": 1.5,
          ">65 F": 1.25,
        },
        {
          id: 5,
          name: "Dairy",
          "2-M": 2,
          "2-F": 2,
          "3-5 M": 2.5,
          "3-5 F": 2.5,
          "6-8 M": 2.5,
          "6-8 F": 2.5,
          "9-11 M": 3,
          "9-11 F": 3,
          "12-14 M": 3,
          "12-14 F": 3,
          "15-25 M": 3,
          "15-25 F": 3,
          "26-65 M": 3,
          "26-65 F": 3,
          ">65 M": 3,
          ">65 F": 3,
        },
      ],
      headers: [
        { text: "#", value: "id" },
        { text: "Name", value: "name" },
        { text: "2-M", value: "2-M" },
        { text: "2-F", value: "2-F" },
        { text: "3-5 M", value: "3-5 M" },
        { text: "3-5 F", value: "3-5 M" },
        { text: "6-8 M", value: "6-8 M" },
        { text: "6-8 F", value: "6-8 F" },
        { text: "9-11 M", value: "9-11 M" },
        { text: "9-11 F", value: "9-11 F" },
        { text: "12-14 M", value: "12-14 M" },
        { text: "12-14 F", value: "12-14 F" },
        { text: "15-25 M", value: "15-25 M" },
        { text: "15-25 F", value: "15-25 F" },
        { text: "26-65 M", value: "26-65 M" },
        { text: "26-65 F", value: "26-65 F" },
        { text: ">65 M", value: ">65 M" },
        { text: ">65 F", value: ">65 F" },
      ],
    };
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
