<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline"
            >{{ employee._id ? "Edit" : "Add" }} Employee</span
          >
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: column;"
        >
          <editable-avatar
            :onImageSelected="onImageSelected"
            :image="employee.image"
          />
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="employee.first_name"
                  label="First Name*"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="employee.last_name"
                  label="Last Name*"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="employee.email"
                  label="Email*"
                  type="email"
                  @onChange="onChange"
                  :rules="[rules.required, rules.email]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="employee.phone"
                  label="Phone*"
                  type="phone"
                  @onChange="onChange"
                  :rules="[rules.required, rules.phone]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="employee.password"
                  label="Password*"
                  type="password"
                  @onChange="onChange"
                  :rules="[rules.required, rules.min]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="employee.role"
                  label="Role*"
                  :items="roleOptions"
                  @onChange="onChange"
                  :rules="[rules.required]"
                  required
                  dense
                  autocomplete="off"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  id="map"
                  label="Address"
                  v-model="employee.address"
                  v-on:placechanged="getAddressData"
                  ref="address"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="employee.address2"
                  label="Address 2"
                  type="address"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="employee.city"
                  label="City"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="employee.state"
                  label="State"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                  :items="stateOptions"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="employee.zip"
                  label="Zip"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text class="pt-0 pl-9">
          <small>*indicates required field</small>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
            {{ employee._id ? "Update" : "Add" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: {
    EditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: Object,
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      roleOptions: ["Manager", "Employee"],
      employee: {},
      error: null,
      loading: false,
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI",
      ],
      selectedFile: null,
    };
  },
  methods: {
    ...mapActions({
      addEmployee: "partner/addEmployee",
      updateEmployee: "partner/editEmployee",
      getValues: "variable/getValues",
    }),
    onSave() {
      this.loading = true;
      var formData = new FormData();
      Object.keys(this.employee).map((key) => {
        if (this.employee[key]) formData.append(key, this.employee[key]);
      });
      if (this.selectedFile) formData.append("image", this.selectedFile);
      if (!this.employee._id) {
        this.addEmployee(formData)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onCloseDialog();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.error = error.response.data.message;
          });
      } else {
        this.updateEmployee(formData)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onCloseDialog();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.error = error.response.data.message;
          });
      }
    },
    onImageSelected(image) {
      this.selectedFile = image;
    },
    getAddressData(addressData) {
      this.employee.address = addressData.name;
      this.employee.state = addressData.administrative_area_level_1;
      this.employee.city = addressData.locality;
      this.employee.zip = addressData.postal_code;
      this.employee.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.employee.address);
    },
    onClose() {
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
  },
  mounted() {
    this.employee = { ...this.item };
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
